<template lang="">
  <base-layout>
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-4 md:grid-cols-2">
          <TitlePlus :title="$t('components.marketPlaceManagement.payout.headline')" :hide-plus="true" />
          <div class="md:flex md:justify-end">
            <DateRangePicker
              @apply-date="onApplyFilterDateRange"
              @cancel-date="onApplyFilterDateRange"
            />
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-2 my-1 md:items-center md:flex-row pl-5">
        <SummaryCard
           :title="$t('components.marketPlaceManagement.payout.summary.totalPayoutCount')"
          :value="indexMetaData.summary.total_payout_count"
          variant="gray"
        />
        <SummaryCard
            :title="$t('components.marketPlaceManagement.payout.summary.totalPayoutAmount')"
          :value="indexMetaData.summary.total_payout_amount"
          :symbol="indexMetaData.currency.symbol"
          variant="gray"
        />
      </div>

      <FSTable
        :fst-id="`AllPayoutHistoryIndex`"
        :searchEnabled="false"
        :headers="getTableHeaders"
        :endpoint="indexDataEndpoint"
        @meta="(e) => (indexMetaData = e)"
      >
        <template #default="{ data }">
          <template v-for="(item, itemIndex) in data">
            <template v-if="getTableMode === 'full'">
              <FSTableRow :key="itemIndex" text-fallback-always>
                <FSTableRowItem :text="item.id" :truncate="-5" />
                <FSTableRowItem
                  :text="item.buyer.id"
                  :to="{
                    name: 'ViewBuyerProfile',
                    params: { id: item.buyer.id },
                  }"
                  :truncate="-5"
                  target="_blank"
                />
                <FSTableRowItem>
                  **{{ $truncate(item.buyer_payout.bank_account_no, -4) }}
                </FSTableRowItem>
                <FSTableRowItem>
                  {{ `${item.currency.symbol} ${item.payout_amount}` }}
                </FSTableRowItem>
                <FSTableRowItem :text="item.payout_date" :date="true" />
                <FSTableRowItem>
                  <XStatus
                    :text="item.payout_status"
                    :variant="getItemVariant(item.order_status)"
                    profile="payment"
                  />
                </FSTableRowItem>
              </FSTableRow>
            </template>
            <template v-if="getTableMode === 'responsive'">
              <FSTableRow
                :key="`fs-table-row-${itemIndex}`"
                text-fallback-always
              >
                <FSTableRowItem>
                  <div
                    class="col-span-1 focus:text-gray-400"
                    @click="toggle(itemIndex)"
                  >
                    <i
                      class="fas fa-minus-circle"
                      style="color:#d90a20;"
                      v-if="opened.includes(itemIndex)"
                    ></i>
                    <i class="fas fa-plus-circle" v-else></i>
                  </div>
                </FSTableRowItem>
                <FSTableRowItem>
                  **{{ $truncate(item.buyer_payout.bank_account_no, -4) }}
                </FSTableRowItem>
                <FSTableRowItem>
                  {{ `${item.currency.symbol} ${item.payout_amount}` }}
                </FSTableRowItem>
                <FSTableRowItem>
                  <XStatus
                    :text="item.payout_status"
                    :variant="getItemVariant(item.order_status)"
                    profile="payment"
                  />
                </FSTableRowItem>
              </FSTableRow>
              <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                <td colspan="10">
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">Id</div>
                    <div class="col-span-5 right-text">
                      {{ $truncate(item.id, -5) }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">Transaction Time</div>
                    <div class="col-span-5 right-text">
                      {{
                        getFormattedDateTime(
                          item.payout_date,
                          'D MMM, YY hh:mm:ss a'
                        )
                      }}
                    </div>
                  </div>
                </td>
              </FSTableRow>
            </template>
          </template>
        </template>
      </FSTable>
    </content-section>
  </base-layout>
</template>
<script>
import BaseLayout from '@/views/shared/BaseLayout.vue'
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import XStatus from '@/components/badge/XStatus'
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  onApplyFilterDateRange,
} from '@/components/fs-table'
import DateRangePicker from '@/components/picker/date-range/DateRangePicker'
import { getFormattedDateTime } from '@/utils/datetime'
import { MarketplaceConfig } from '@/config/MarketplaceConfig'
export default {
  name: 'MarketplacePayoutIndex',
  components: {
    SummaryCard: () => import('@/components/cards/SummaryCard'),
    BaseLayout,
    DateRangePicker,
    ContentSection,
    TitlePlus,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    XStatus,
  },
  data() {
    return {
      indexDataEndpoint: MarketplaceConfig.api.marketplaceAllPayoutIndex(),
      indexMetaData: {
        currency: {
          symbol: '--',
        },
        summary: {
          total_payout_amount: '',
          total_payout_count: 0,
        },
      },
      windowWidth: window.innerWidth,
      opened: [],
      tableHeaders: [
        { text: this.$t('components.marketPlaceManagement.payout.table.columns.id'), width: '5%', sort: 'id' },
        { text: this.$t('components.marketPlaceManagement.payout.table.columns.buyerId'), width: '5%', sort: 'null' },
        { text: this.$t('components.marketPlaceManagement.payout.table.columns.accountNo'), width: '10%', sort: 'null' },
        { text: this.$t('components.marketPlaceManagement.payout.table.columns.Amount'), width: '10%', sort: 'null' },
        { text: this.$t('components.marketPlaceManagement.payout.table.columns.transactionTime'),  width: '10%', sort: 'null' },
        { text: this.$t('components.marketPlaceManagement.payout.table.columns.status'),  width: '10%', sort: 'null' },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        { text: 'Account No', width: '30%', sort: 'null' },
        { text: 'Amount', width: '25%', sort: null },
        { text: 'Status', width: '25%', sort: 'null' },
      ],
    }
  },
  created() {
    localStorage.removeItem('route-path')
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
  },
  methods: {
    getFormattedDateTime,
    onApplyFilterDateRange,
    onResize() {
      this.windowWidth = window.innerWidth
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    getItemVariant(data) {
      if (data === 'paid') return 'green'
      if (data === 'failed') return 'red'
      return 'orange'
    },
  },
}
</script>
<style lang="scss">
.left-text {
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
  //text-align: right;
}
.history-box {
  min-height: 100vh;
}
</style>
